import PropTypes from 'prop-types';
import Text from '../atoms/Text';
import Icon from '@/atoms/Icon';

const Lists = ({ lists, listIcon, textVariant, textColor, listSpace }) => {
    return (
        <div className={`flex flex-col ${listSpace}`}>
            {lists.map((menu, index) => (
                <div key={index} className="flex gap-3">
                    <Icon src={listIcon} width={20} height={20} alt="icon" />
                    <Text fontSize={textVariant} textColor={textColor}>
                        {menu.title}
                    </Text>
                </div>
            ))}
        </div>
    );
};

Lists.defaultProps = {
    lists: [],
    listIcon: '/images/icons/icon_check.svg',
    textVariant: 'text-body1 md:text-body4',
    textColor: 'text-grey-shade2',
    listSpace: 'gap-2',
};

Lists.propTypes = {
    lists: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    listIcon: PropTypes.string,
    textVariant: PropTypes.string,
    textColor: PropTypes.string,
    listSpace: PropTypes.string,
};

export default Lists;
