import PropTypes from 'prop-types';
import Image from 'next/image';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import Lists from '@/molecules/Lists';
import Tag from '@/atoms/Tag';
import Button from '@/atoms/Button';

const UpgradeCart = ({ plan, onUpgradePlan }) => {
    const myLoader = ({ src }) => src;
    return (
        <div className="relative">
            <div className="bg-primary-default w-full rounded-xl px-6 py-3.5 absolute top-0">
                <Heading
                    type="h4"
                    fontFamily="font-body"
                    fontWeight="font-medium"
                    className="text-white text-center"
                    fontSize="text-body2 md:text-h4"
                >
                    {plan.upgradeHeading}
                </Heading>
            </div>

            <div className="bg-grey-shade1 pt-20 pb-6 px-4 md:px-6 rounded-xl">
                <div className="flex items-start gap-3 msm:gap-4 md:gap-7 justify-between">
                    <span className="absolute right-0 top-0 z-0">
                        <Image
                            loader={myLoader}
                            src="/images/ellipse_vector.png"
                            width={248}
                            height={308}
                            alt=""
                        />
                    </span>

                    <Image
                        loader={myLoader}
                        src={plan.planImg}
                        width={180}
                        height={180}
                        alt="blog image"
                        className="flex shrink-0 rounded-xl max-w-[100px] msm:max-w-[120px] md:max-w-[180px]"
                    />
                    <div className="w-full relative">
                        <div className="flex flex-wrap items-center justify-between gap-1 md:gap-3 mb-4">
                            <Heading
                                type="h5"
                                fontFamily="font-body"
                                fontWeight="font-medium"
                                fontColor="text-white"
                            >
                                {plan.planHeading}
                            </Heading>

                            <Tag
                                icon={false}
                                className="text-grey-shade1 p-1 whitespace-pre"
                                style="primary"
                                shape="soft"
                                size="extraSmall"
                            >
                                for
                                <span className="font-medium ml-1">
                                    {plan.duration}
                                </span>
                            </Tag>
                        </div>

                        <Text
                            variant="body-1"
                            fontWeight="font-medium"
                            textColor=" text-white"
                            className="mb-2"
                        >
                            You get:
                        </Text>

                        <Lists
                            listIcon="/images/icons/icon_check_w.svg"
                            listSpace="gap-1"
                            lists={plan.featureList}
                            textColor="text-white"
                        />
                        <hr className="border-grey-shade5 mt-4 mb-5" />

                        <div className="flex flex-wrap justify-between gap-4">
                            <div className="flex gap-x-6 justify-between">
                                {/* <Text
                                variant="body3"
                                textColor="text-white"
                                className="block md:hidden"
                            >
                                Sub Total:
                            </Text> */}
                                <Heading
                                    type="h2"
                                    fontColor="text-white"
                                    fontSize="text-h3/[28px] md:text-h2"
                                    fontWeight="font-normal"
                                    fontFamily="font-sectionHeading"
                                >
                                    ₹{plan.price}
                                </Heading>
                            </div>

                            <Button
                                style="ghost"
                                label="Upgrade Plan"
                                size="medium"
                                onClick={onUpgradePlan}
                                btnClass="w-full md:w-max hidden md:block"
                            />
                        </div>
                    </div>
                </div>

                <Button
                    style="ghost"
                    label="Upgrade Plan"
                    size="medium"
                    onClick={onUpgradePlan}
                    btnClass="w-full mt-4 md:w-max md:hidden"
                />
            </div>
        </div>
    );
};

UpgradeCart.defaultProps = {
    plan: {},
    onUpgradePlan: () => {},
};

UpgradeCart.propTypes = {
    plan: PropTypes.shape({
        upgradeHeading: PropTypes.string,
        planImg: PropTypes.string,
        planHeading: PropTypes.string,
        duration: PropTypes.string,
        featureList: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
            })
        ),
        subTotalText: PropTypes.string,
        price: PropTypes.string,
    }),
    onUpgradePlan: PropTypes.func,
};

export default UpgradeCart;
