import Image from 'next/image';
import Text from '@/atoms/Text';

const EmptyCart = () => {
    const myLoader = ({ src }) => src;
    return (
        <div className="text-center md:mt-10">
            <Image
                loader={myLoader}
                src="/images/empty_cart.png"
                width={200}
                height={200}
                alt=""
                className="mx-auto max-w-[140px] md:max-w-[200px]"
            />
            <Text
                variant="subHeading"
                className="mt-4 mb-1"
                textColor="text-grey-shade1"
                fontSize="text-2xl"
                fontWeight="font-medium"
            >
                Your cart is empty
            </Text>
            <Text variant="body1">
                Check out our plans for psoriasis management
            </Text>
        </div>
    );
};

export default EmptyCart;
