import { getAuth } from '@/services/identity.service';

export const isChemist = () => {
    const auth = getAuth();
    return !!auth?.chemistId;
};
export const isBackOfficeUser = () => {
    const auth = getAuth();
    return !!auth?.backOfficeUserId;
};
