import PropTypes from 'prop-types';
import Button from './Button';

const StickyButton = ({
    label,
    handleSubmit,
    isSecondaryBtn,
    secondaryBtnLabel,
    handleSecondaryBtn,
    type = 'button',
    disabled,
    isNoteText,
    noteTextClass,
    noteText,
}) => {
    return (
        <div className="fixed left-0 bottom-0 px-5 py-8 md:px-10 md:py-10 bg-white w-full flex flex-col gap-2 md:gap-5">
            {isNoteText && <div className={noteTextClass}>{noteText}</div>}
            <Button
                disabled={disabled}
                label={label}
                size="large"
                btnClass="w-full"
                onClick={handleSubmit}
                type={type}
            />
            {isSecondaryBtn && (
                <Button
                    disabled={disabled}
                    label={secondaryBtnLabel}
                    style="ghost"
                    size="large"
                    btnClass="w-full"
                    onClick={handleSecondaryBtn}
                />
            )}
        </div>
    );
};

StickyButton.defaultProps = {
    label: 'Buy Now',
    handleSubmit: () => {},
    isSecondaryBtn: false,
    secondaryBtnLabel: '',
    handleSecondaryBtn: () => {},
    isNoteText: false,
    noteTextClass: '',
    noteText: '',
};

StickyButton.propTypes = {
    label: PropTypes.string,
    handleSubmit: PropTypes.func,
    isSecondaryBtn: PropTypes.bool,
    secondaryBtnLabel: PropTypes.string,
    handleSecondaryBtn: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.string,
    isNoteText: PropTypes.bool,
    noteTextClass: PropTypes.string,
    noteText: PropTypes.string,
};
export default StickyButton;
