import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from './Icon';

const tagStyles = {
    primary: 'bg-primary-shade3 text-grey-shade2 fill-white',
    warning: 'bg-warning-100 text-grey-shade2 ',
    error: 'bg-error-100 text-white',
    success: 'bg-success-100 text-grey-shade2 ',
    normal: 'bg-white text-grey-shade2 ',
};

const sizes = {
    small: 'px-2 py-1 text-xs',
    default: 'px-2 py-1 text-sm',
    medium: 'px-6 py-2 text-base',
    extraSmall: 'p-1 text-xs/[14px]',
};

const shapes = {
    rounded: 'rounded-full',
    soft: 'rounded',
    sharp: 'sharp',
};

const iconPositions = {
    left: 'flex-row-reverse space-x-reverse space-x-2',
    right: 'flex-row space-x-2',
};

const Tag = ({
    style,
    size,
    shape,
    iconPosition,
    children,
    icon,
    onClick,
    iconSrc,
    iconWidth,
    iconHeight,
    iconAlt,
    className,
}) => {
    return (
        <div
            className={classnames(
                'cursor-pointer relative inline-flex items-center',
                tagStyles[style],
                sizes[size],
                shapes[shape],
                className,
                iconPositions[iconPosition]
            )}
            onClick={onClick}
        >
            {children}
            {icon && (
                <Icon
                    iconSrc={iconSrc}
                    iconWidth={iconWidth}
                    iconHeight={iconHeight}
                    iconAlt={iconAlt}
                />
            )}
        </div>
    );
};

Tag.defaultProps = {
    iconSrc: '/images/icons/closeIcon.svg',
    style: 'primary',
    size: 'default',
    shape: 'rounded',
    iconPosition: 'right',
    title: '',
    className: '',
    icon: true,
    onClick: () => {},
    iconWidth: '13',
    iconHeight: '13',
    iconAlt: '',
};

Tag.propTypes = {
    style: PropTypes.oneOf([
        'primary',
        'extendedPalette',
        'neutral',
        'light',
        'border',
        'borderLess',
    ]),
    size: PropTypes.oneOf(['small', 'default', 'medium', 'extraSmall']),
    shape: PropTypes.oneOf(['rounded', 'soft', 'sharp']),
    iconPosition: PropTypes.oneOf(['left', 'right']),
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.bool,
    onClick: PropTypes.func,
    iconSrc: PropTypes.string,
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string,
    iconAlt: PropTypes.string,
};
export default Tag;
