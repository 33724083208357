import PropTypes from 'prop-types';
import Navbar from '@/molecules/Navbar';
import Footer from '@/organisms/Footer';
import { redirectTo } from 'utils/router';
import { useEffect, useState } from 'react';
import { useGetPlans } from '@/services/plan.queries';
import { usePlanStore } from 'store/plans';
import { useEmailSubscriptionMutation } from '@/services/email-subscription.queries';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAuth, removeAuth, setAuth } from '@/services/identity.service';
import Cart from '@/organisms/Cart';
import {
    CARE_PLAN,
    DEVICE_ONLY_PLAN,
    upgradeCartPlanAdditionalDetails,
    uvDeviceCartPlanAdditionalDetails,
    uvDeviceMoreFeatures,
    carePlanMoreFeatures,
    CARE_PLAN_NAME,
    DEVICE_ONLY_PLAN_NAME,
} from 'constants/plan-constants';
import { useRouter } from 'next/router';
import { useGetPsoriasisOrdersCountByLeadIdQuery } from '@/services/profile.queries';
import { useProfileStore } from 'store/profile';
import useRequestCallbackHook from '@/services/requestCallbackHook';
import LogoStrip from '@/molecules/LogoStrip';
import { useRedirectUrlStore } from 'store/redirectUrl';
import { deviceOnlyPlanFeature } from 'constants/payment-constants';
import { NAVBAR_MESSAGE } from 'constants/message-constants';
import { isChemist } from 'helpers/auth';

const Layout = ({
    showHeader,
    showFooter,
    showlogoStrip,
    children = <></>,
}) => {
    const [cartPlan, setCartPlan] = useState({});
    const [upgradeCartPlan, setUpgradeCartPlan] = useState({});
    const [, setPlanDetail] = useState({});
    const router = useRouter();

    const { data: plans, isLoading: isPlansLoading } = useGetPlans();
    const auth = getAuth();
    const setPlans = usePlanStore((state) => state.setPlans);

    const carePlanDetails = usePlanStore((state) => state.carePlanDetails);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const selectedPlanId = usePlanStore((state) => state.selectedPlanId);
    const setSelectedPlanId = usePlanStore((state) => state.setSelectedPlanId);
    const isOpenCart = usePlanStore((state) => state.isOpenCart);
    const setOpenCart = usePlanStore((state) => state.setOpenCart);

    const ordersCount = useProfileStore((state) => state.ordersCount);
    const setOrdersCount = useProfileStore((state) => state.setOrdersCount);

    const { data: countOfOrders, isOrdersCountLoading } =
        useGetPsoriasisOrdersCountByLeadIdQuery({ leadId: auth?.id });
    const setRedirectUrl = useRedirectUrlStore((state) => state.setRedirectUrl);

    useEffect(() => {
        if (isPlansLoading) return;
        setPlans(plans);
    }, [isPlansLoading, plans]);

    useEffect(() => {
        if (isOrdersCountLoading) return;
        setOrdersCount(countOfOrders);
    }, [isOrdersCountLoading, countOfOrders]);

    useEffect(() => {
        if (!selectedPlanId && auth?.planId) {
            setSelectedPlanId(auth.planId);
        }
        setOpenCart(false);
    }, [router.asPath]);

    useEffect(() => {
        const selectedPlan = plans?.find((plan) => plan.id === selectedPlanId);
        if (selectedPlan) {
            let planDetail = {
                amount: selectedPlan.price,
                name: selectedPlan.name,
            };
            if (selectedPlan.name === DEVICE_ONLY_PLAN) {
                const carePlan = plans.find((plan) => plan.name === CARE_PLAN);

                planDetail = {
                    ...planDetail,
                    rating: 4,
                    planImage: '/images/care_plan_uv_ultra.png',
                    timePeriod: 'for 12 weeks',
                    totalRatings: 156,
                    description:
                        'Get advanced UV light therapy in the comfort of your home',
                    title: 'UV Device Only Plan',
                    deliveryTime: '4 days',
                    featuresList: [
                        {
                            id: 1,
                            imgSrc: '/images/icons/phonelink.svg',
                            text: 'Smart app control',
                        },
                        {
                            id: 2,
                            imgSrc: '/images/icons/fluorescent.svg',
                            text: 'Long usage UVB lamp',
                        },
                        {
                            id: 3,
                            imgSrc: '/images/icons/waterproof.svg',
                            text: 'Dust and water-proof',
                        },
                        {
                            id: 4,
                            imgSrc: '/images/icons/timer.svg',
                            text: 'Automatic timer',
                        },
                    ],
                    sliderImages: [
                        {
                            id: 1,
                            src: 'https://marketplace.canva.com/EAFp47YI0_g/2/0/800w/canva-modern-animated-neutral-thank-you-video-VVpYZrSzuBI.mp4',
                            showVideo: true,
                            thumbSrc: '/images/device_2.svg',
                        },
                        {
                            id: 2,
                            src: '/images/device_2.svg',
                            thumbSrc: '/images/device_2.svg',
                        },
                        {
                            id: 3,
                            src: '/images/device.svg',
                            thumbSrc: '/images/device.svg',
                        },
                        {
                            id: 4,
                            src: '/images/uv_device.svg',
                            thumbSrc: '/images/uv_device.svg',
                        },
                    ],
                    moreFeatures: uvDeviceMoreFeatures,
                };
                setUpgradeCartPlan({
                    planHeading: carePlan.name,
                    price: carePlan.price,
                    ...upgradeCartPlanAdditionalDetails,
                });
            } else if (selectedPlan.name === CARE_PLAN) {
                planDetail = {
                    ...planDetail,
                    rating: 4.5,
                    timePeriod: 'for 12 weeks',
                    planImage: '/images/cart_planImg.png',
                    totalRatings: 168,
                    description:
                        'Get a 360° plan that brings lasting relief from psoriasis',
                    title: 'UV Device + Care Plan',
                    deliveryTime: '3 days',
                    featuresList: [
                        {
                            id: 1,
                            imgSrc: '/images/icons/phonelink.svg',
                            text: 'Smart app control',
                        },
                        {
                            id: 2,
                            imgSrc: '/images/icons/fluorescent.svg',
                            text: 'Long usage UVB lamp',
                        },
                        {
                            id: 3,
                            imgSrc: '/images/icons/waterproof.svg',
                            text: 'Dust and water-proof',
                        },
                        {
                            id: 4,
                            imgSrc: '/images/icons/timer.svg',
                            text: 'Automatic timer',
                        },
                    ],
                    sliderImages: [
                        {
                            id: 1,
                            src: 'https://marketplace.canva.com/EAFp47YI0_g/2/0/800w/canva-modern-animated-neutral-thank-you-video-VVpYZrSzuBI.mp4',
                            showVideo: true,
                            thumbSrc: '/images/device_2.svg',
                        },
                        {
                            id: 2,
                            src: '/images/device_2.svg',
                            thumbSrc: '/images/device_2.svg',
                        },
                        {
                            id: 3,
                            src: '/images/device.svg',
                            thumbSrc: '/images/device.svg',
                        },
                        {
                            id: 4,
                            src: '/images/uv_device.svg',
                            thumbSrc: '/images/uv_device.svg',
                        },
                    ],
                    moreFeatures: carePlanMoreFeatures,
                };
                setUpgradeCartPlan({});
            }
            setPlanDetail(planDetail);
            const cartPlanDetails = {
                planName: planDetail.name,
                planImg: planDetail.planImage,
                planHeading: planDetail.title,
                duration: planDetail.timePeriod,
                price: planDetail.amount,
                featureList:
                    selectedPlan.name === DEVICE_ONLY_PLAN
                        ? deviceOnlyPlanFeature
                        : upgradeCartPlanAdditionalDetails.featureList,
            };
            setCartPlan(cartPlanDetails);
        }
    }, [plans, selectedPlanId]);

    const menuItem = [
        { title: 'Home', href: '/', isActive: router.pathname === '/' },
        {
            title: 'Buy Device + Care Plan',
            href: `/plans/${CARE_PLAN_NAME}`,
            isActive: router.query.planName === 'careplan',
        },
        {
            title: 'Buy UV Device',
            href: `/plans/${DEVICE_ONLY_PLAN_NAME}`,
            isActive: router.query.planName === 'uvdevice',
        },
        {
            title: 'Explore',
            href: '/explore',
            isActive: router.pathname === '/explore',
        },
        { title: 'FAQs', href: '/faqs', isActive: router.pathname === '/faqs' },
    ];

    const handleCart = () => {
        setOpenCart(true);
    };

    const handleProfile = () => {
        if (auth?.id_token) {
            redirectTo('/purchase-history');
        } else {
            setRedirectUrl('/purchase-history');
            redirectTo('/login');
        }
    };

    const { handleTicket } = useRequestCallbackHook();

    const handleCallBack = () => {
        handleTicket(NAVBAR_MESSAGE);
    };

    const handleTrackOrder = () => {
        redirectTo('/track-order');
    };

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email('Enter a valid email address!')
            .required('Email is required!'),
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    const { createEmailSubscriptionMutation } = useEmailSubscriptionMutation();

    const onSubmit = (data) => {
        let bodyData = { email: data.email };
        if (auth?.id) {
            bodyData.leadId = auth.id;
        }

        createEmailSubscriptionMutation.mutate(bodyData, {
            onSuccess: () => {
                setShowSuccessMessage(true);
                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 3000);
            },
        });
        reset({ email: '' });
    };

    // const handleBuyNow = () => {
    //     if (planDetail.name === DEVICE_ONLY_PLAN) {
    //         const carePlan = plans.find((plan) => plan.name === CARE_PLAN);
    //         setUpgradeCartPlan({
    //             planHeading: carePlan.name,
    //             price: carePlan.price,
    //             ...upgradeCartPlanAdditionalDetails,
    //         });
    //     }
    //     const cartPlanDetails = {
    //         planName: planDetail.name,
    //         planImg: planDetail.planImage,
    //         planHeading: planDetail.title,
    //         duration: planDetail.timePeriod,
    //         price: planDetail.amount,
    //         featureList: [{ title: 'Advanced UV device' }],
    //     };
    //     setCartPlan(cartPlanDetails);
    //     setOpenCart(false);
    //     setAuth({ planId: router.query.planId });
    // };

    const handleBuyCarePlan = () => {
        if (auth?.id_token && ordersCount > 0) {
            redirectTo('/purchase-history');
            return;
        }
        setSelectedPlanId(carePlanDetails.id);
        setAuth({ planId: carePlanDetails.id });
        setOpenCart(true);
    };

    const onUpgradePlan = () => {
        const carePlan = plans.find((plan) => plan.name === CARE_PLAN);
        const cartPlanDetails = {
            planName: carePlan.name,
            price: carePlan.price,
            ...uvDeviceCartPlanAdditionalDetails,
        };
        setUpgradeCartPlan({});
        setCartPlan(cartPlanDetails);
        setAuth({ planId: carePlan.id });
        setSelectedPlanId(carePlan.id);
    };

    const handleBuy = () => {
        setOpenCart(false);
        redirectTo(isChemist() ? '/payment' : '/delivery-details');
    };

    const onLogout = () => {
        let isLoggedIn = auth?.id_token;
        removeAuth();
        if (isLoggedIn) {
            redirectTo('/purchase-history');
        } else {
            redirectTo('/login');
        }
    };

    const footerMenuGroup = [
        {
            title: 'Home',
            items: [
                { href: '/#what-is-dailyBloom', label: 'What is Dailybloom' },
                { href: '/#uv-device', label: 'UV Device' },
                { href: '/#results', label: 'Results' },
                { href: '/#plans-and-pricing', label: 'Plans and Pricing' },
                { href: '/#plan-overview', label: 'Plan overview' },
                { href: '/#explore', label: 'Explore' },
                { href: '/#faq', label: 'FAQs' },
            ],
        },
        {
            title: 'Device-only Plan',
            items: [
                {
                    href: `/plans/${DEVICE_ONLY_PLAN_NAME}/#what-is-it`,
                    label: 'What is it',
                },
                {
                    href: `/plans/${DEVICE_ONLY_PLAN_NAME}/#how-it-works`,
                    label: 'How it works',
                },
                {
                    href: `/plans/${DEVICE_ONLY_PLAN_NAME}/#testimonials`,
                    label: 'Testimonials',
                },
                {
                    href: `/plans/${DEVICE_ONLY_PLAN_NAME}/#safetyInstructions`,
                    label: 'Safety Instructions',
                },
                {
                    href: `/plans/${DEVICE_ONLY_PLAN_NAME}/#explore`,
                    label: 'Explore',
                },
            ],
        },
        {
            title: 'Device + Care Plan',
            items: [
                {
                    href: `/plans/${CARE_PLAN_NAME}/#what-is-it`,
                    label: 'What is it',
                },
                {
                    href: `/plans/${CARE_PLAN_NAME}/#how-it-works`,
                    label: 'How it works',
                },
                {
                    href: `/plans/${CARE_PLAN_NAME}/#testimonials`,
                    label: 'Testimonials',
                },
                {
                    href: `/plans/${CARE_PLAN_NAME}/#your-coaches`,
                    label: 'Your Coaches',
                },
                {
                    href: `/plans/${CARE_PLAN_NAME}/#explore`,
                    label: 'Explore',
                },
            ],
        },
        {
            title: 'Explore',
            items: [
                { href: '/explore/#all', label: 'All articles' },
                {
                    href: `/plans/${CARE_PLAN_NAME}`,
                    label: 'Device and Care Plan',
                },
                {
                    href: '/explore/#success-stories',
                    label: 'Success stories',
                },
            ],
        },
        {
            title: 'Your account',
            items: [
                { href: '/purchase-history', label: 'Purchase history' },
                { href: '/personal-details', label: 'Personal details' },
                { href: '/help-support', label: 'Help & support' },
                {
                    href: '/personal-details',
                    label: 'Logout',
                    handleOnClick: onLogout,
                },
            ],
        },
    ];
    const [isShowPrivacyPolicyModal, setShowPrivacyPolicyModal] =
        useState(false);
    const [isTermsConditionsShowModal, setShowTermsConditions] =
        useState(false);

    return (
        <div className="overflow-hidden lg:overflow-visible">
            {showHeader && (
                <Navbar
                    menuItem={menuItem}
                    handleCart={handleCart}
                    handleProfile={handleProfile}
                    handleCallBack={handleCallBack}
                    handleTrackOrder={handleTrackOrder}
                    isOpenCart={isOpenCart}
                    setOpenCart={setOpenCart}
                    count={selectedPlanId ? 1 : 0}
                />
            )}
            <main className={showHeader ? 'pt-[72px] lg:pt-[88px]' : 'pt-0'}>
                {showlogoStrip && <LogoStrip />}
                {children}
            </main>
            {showFooter && (
                <Footer
                    register={register}
                    handleSubmit={handleSubmit(onSubmit)}
                    footerMenuGroup={footerMenuGroup}
                    errors={errors}
                    showSuccessMessage={showSuccessMessage}
                    handleBuyNow={handleBuyCarePlan}
                    privacyPolicyLink="/pdf/dummy-pdf.pdf"
                    termsConditionsLink="/pdf/dummy-pdf.pdf"
                    handlePrivacyPolicy={() => {
                        setShowPrivacyPolicyModal(true);
                    }}
                    handleTermsConditions={() => {
                        setShowTermsConditions(true);
                    }}
                    isShowPrivacyPolicyModal={isShowPrivacyPolicyModal}
                    closePrivacyPolicyModal={() => {
                        setShowPrivacyPolicyModal(false);
                    }}
                    isTermsConditionsShowModal={isTermsConditionsShowModal}
                    closeTermsConditionsModal={() => {
                        setShowTermsConditions(false);
                    }}
                />
            )}
            <Cart
                isOpenCart={isOpenCart}
                setOpenCart={setOpenCart}
                cartPlan={cartPlan}
                upgradeCartPlan={upgradeCartPlan}
                selectedPlanId={selectedPlanId}
                handleBuy={handleBuy}
                onUpgradePlan={onUpgradePlan}
            />
        </div>
    );
};

Layout.propTypes = {
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    showlogoStrip: PropTypes.bool,
    children: PropTypes.node,
};

Layout.defaultProps = {
    showHeader: true,
    showFooter: true,
    showlogoStrip: true,
};

export default Layout;
