import PropTypes from 'prop-types';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import Image from 'next/image';
import Lists from '@/molecules/Lists';
import Tag from '@/atoms/Tag';

const CartList = ({ isShowTag, plan }) => {
    const myLoader = ({ src }) => src;
    return (
        <div
            className={`flex px-4 ${isShowTag ? 'pt-0 py-4' : 'py-6'} md:px-6 gap-3 msm:gap-4 md:gap-7 items-start border border-grey-shade5 bg-white rounded-xl`}
        >
            <div className="shrink-0 text-center">
                {isShowTag && (
                    <Tag
                        icon={false}
                        className="text-xs text-grey-shade2 py-1 px-4 whitespace-pre"
                        style="success"
                        shape="soft"
                        size="extraSmall"
                    >
                        Upgraded Plan
                    </Tag>
                )}
                <Image
                    loader={myLoader}
                    src={plan.planImg}
                    width={170}
                    height={170}
                    alt="blog image"
                    className="rounded-xl max-w-[100px] msm:max-w-[120px] md:max-w-[180px]"
                />
            </div>

            <div className={`w-full ${isShowTag && 'mt-6'}`}>
                <div className="flex flex-wrap items-center justify-between gap-1 md:gap-3 mb-4">
                    <Heading
                        type="h5"
                        fontFamily="font-body"
                        fontWeight="font-medium"
                        fontColor="text-grey-shade1"
                    >
                        {plan.planHeading}
                    </Heading>

                    <Tag
                        icon={false}
                        className="text-grey-shade1 p-1 whitespace-pre"
                        style="primary"
                        shape="soft"
                        size="extraSmall"
                    >
                        <span className="font-medium ml-1">
                            {plan.duration}
                        </span>
                    </Tag>
                </div>

                <Text
                    variant="body-1"
                    fontWeight="font-medium"
                    className="mb-2"
                >
                    You get:
                </Text>
                <Lists
                    listIcon="/images/icons/icon_check.svg"
                    lists={plan.featureList}
                    listSpace="gap-1"
                />
                <hr className="border-grey-shade4 mt-3 mb-4" />
                <div className="flex  items-center gap-x-6 justify-between">
                    {/* <Text variant="body3">Sub Total:</Text> */}
                    <Heading
                        type="h2"
                        fontWeight="font-normal"
                        fontFamily="font-sectionHeading"
                        fontColor="text-grey-shade1"
                        fontSize="text-h3/[28px] md:text-h2"
                    >
                        ₹{plan.price}
                    </Heading>
                </div>
            </div>
        </div>
    );
};

CartList.defaultProps = {
    plan: {},
    isShowTag: false,
};

CartList.propTypes = {
    plan: PropTypes.shape({
        planImg: PropTypes.string,
        planHeading: PropTypes.string,
        duration: PropTypes.string,
        featureList: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
            })
        ),
        price: PropTypes.string,
    }),
    isShowTag: PropTypes.bool,
};

export default CartList;
