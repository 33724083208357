import PropTypes from 'prop-types';
import Heading from '@/atoms/Heading';
import InputBox from '@/atoms/InputBox';
import Button from '@/atoms/Button';

const GetInTouch = ({
    register,
    handleSubmit,
    email,
    errors,
    handleBuyNow,
    showSuccessMessage,
}) => {
    return (
        <div className="bg-grey-shade6 pb-8">
            <div className="container xl:max-w-[1360px]">
                <div className="flex flex-wrap items-center md:justify-between lg:justify-start md:flex-nowrap gap-5 w-full pt-10 pb-8 mb-8 border-y border-grey-shade5">
                    <Heading
                        type="h4"
                        fontSize="text-2xl/[28px]"
                        fontWeight="font-medium"
                        fontFamily="font-body"
                        className="pt-0.5 text-center md:text-left w-auto md:w-[434px] lg:w-auto"
                    >
                        Find a scientifically-proven way to manage psoriasis
                    </Heading>

                    <Button
                        size="large3"
                        label="Buy Now"
                        onClick={handleBuyNow}
                        btnClass="flex-shrink-0 w-full md:w-auto"
                    />
                </div>

                <div className="flex flex-col lg:flex-row gap-5 w-full">
                    <Heading
                        type="h4"
                        fontSize="text-2xl/[28px]"
                        fontWeight="font-medium"
                        fontFamily="font-body"
                        className="pt-0.5 text-center md:text-left xl:whitespace-nowrap"
                    >
                        Want the latest updates in psoriasis management?
                    </Heading>
                    <div className="flex flex-col md:flex-row gap-5 items-center md:items-start md:w-[490px] w-full flex-shrink-0">
                        <div className="flex flex-col items-start  w-full justify-start">
                            <InputBox
                                id="email"
                                name="email"
                                type="text"
                                inputStyle="small"
                                placeholder="Enter Email ID"
                                register={register}
                                variant="SmallResponsive"
                                isLabel={false}
                                inputClass="w-full md:max-w-[342px]"
                                value={email}
                                dbName="email"
                                isError={errors?.email ? true : false}
                                errorMessage={errors?.email?.message}
                            />
                            {showSuccessMessage && (
                                <span className="text-sm mt-2 font-medium  text-green-600 pl-1">
                                    Submitted successfully!!
                                </span>
                            )}
                        </div>

                        <Button
                            size="large3"
                            label="Stay in touch"
                            onClick={handleSubmit}
                            btnClass="flex-shrink-0 w-full md:w-auto"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

GetInTouch.defaultProps = {
    register: () => {},
    handleSubmit: () => {},
    handleBuyNow: () => {},
};

GetInTouch.propTypes = {
    register: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleBuyNow: PropTypes.func,
    email: PropTypes.string,
    errors: PropTypes.object,
    showSuccessMessage: PropTypes.bool,
};

export default GetInTouch;
