import PropTypes from 'prop-types';
import Icon from '@/atoms/Icon';
import Heading from '@/atoms/Heading';
import { useEffect } from 'react';

const SlideOver = ({
    slideWidth,
    slideHeight,
    slideOverClass,
    isHeader,
    headerClass,
    headerTitle,
    isSlideOverOpen,
    bodyBaseClass,
    bodyClass,
    bodyHeight,
    children,
    onClose = () => false,
    setSlideOverOpen,
}) => {
    useEffect(() => {
        if (isSlideOverOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isSlideOverOpen]);

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('slide-over-background')) {
            setSlideOverOpen(false);
        }
    };

    return (
        <>
            {isSlideOverOpen && (
                <div
                    className="fixed inset-0 bg-black opacity-50 z-[100] slide-over-background transition-opacity"
                    onClick={handleOutsideClick}
                />
            )}

            <div
                className={`fixed overflow-hidden bottom-0 md:inset-y-0 left-0 md:left-auto md:right-0 z-[100] bg-white shadow-lg transform transition-transform duration-500 rounded-tl-[24px] rounded-tr-[24px] md:rounded-bl-[24px] md:rounded-tr-[0] md:h-full ${slideHeight} ${slideWidth} ${slideOverClass} ${
                    isSlideOverOpen
                        ? 'translate-y-0 md:translate-x-0'
                        : 'translate-y-full md:translate-x-full md:translate-y-0'
                }`}
            >
                {isHeader && (
                    <div
                        className={`flex justify-between items-center pt-6 pb-3 md:pt-9 md:pb-7  border-b ${headerClass}`}
                    >
                        <Heading
                            type="h3"
                            fontFamily="font-body md:font-heading"
                            fontSize="text-xl md:text-[28px]/[36px]"
                            className="flex items-center"
                        >
                            {headerTitle}
                        </Heading>
                        <span
                            onClick={() => setSlideOverOpen(false)}
                            className="cursor-pointer"
                        >
                            <Icon
                                onClick={onClose}
                                src="/images/icons/icon_close.svg"
                                width={44}
                                height={44}
                                alt="close"
                                className="w-10 md:w-[44px]"
                            />
                        </span>
                    </div>
                )}
                <div
                    className={`${bodyBaseClass} ${bodyClass} ${bodyHeight} overflow-y-auto`}
                >
                    {children}
                </div>
            </div>
        </>
    );
};

SlideOver.defaultProps = {
    isHeader: true,
    headerClass: 'px-5 md:px-0',
    headerTitle: 'Heading',
    isSlideOverOpen: false,
    slideWidth: 'w-full md:w-[610px]',
    slideHeight: '',
    slideOverClass: 'px-0 md:px-10',
    bodyBaseClass: 'px-5 pt-6 md:pt-8 md:px-0',
    bodyClass: '',
    bodyHeight: 'max-h-[calc(100vh_-_76px)] md:max-h-[calc(100vh_-_108px)]',
    onClose: () => false,
};

SlideOver.propTypes = {
    isSlideOverOpen: PropTypes.bool,
    isHeader: PropTypes.bool,
    headerClass: PropTypes.string,
    headerTitle: PropTypes.string,
    slideWidth: PropTypes.string,
    slideHeight: PropTypes.string,
    slideOverClass: PropTypes.string,
    bodyBaseClass: PropTypes.string,
    bodyClass: PropTypes.string,
    bodyHeight: PropTypes.string,
    children: PropTypes.node,
    setSlideOverOpen: PropTypes.func,
    onClose: PropTypes.func,
};

export default SlideOver;
