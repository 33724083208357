export const isObjectEmpty = (value = {}) => {
    return Object.keys(value).length === 0;
};

export const isObjectNotEmpty = (value = {}) => {
    return Object.keys(value).length !== 0;
};

export const omitKey = (obj = {}, key) => {
    return Object.fromEntries(Object.entries(obj).filter(([k]) => k !== key));
};
