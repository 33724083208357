import PropTypes from 'prop-types';
import Heading from './Heading';

const SectionHeading = ({
    children,
    className,
    fontSize,
    fontWeight,
    fontColor,
    fontFamily,
    align,
}) => {
    return (
        <Heading
            type="h2"
            fontSize={fontSize}
            fontWeight={fontWeight}
            align={align}
            fontColor={fontColor}
            fontFamily={fontFamily}
            className={className}
        >
            {children}
        </Heading>
    );
};

SectionHeading.defaultProps = {
    fontSize: 'text-[42px]/[44px] lg:text-5xl',
    className: 'lg:tracking-[-0.75px]',
    fontWeight: 'font-medium',
    fontColor: 'text-grey-shade1',
    fontFamily: 'font-sectionHeading',
    align: 'left',
};

SectionHeading.propTypes = {
    className: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.oneOf([
        'font-heading',
        'font-body',
        'font-sectionHeading',
    ]),
    fontColor: PropTypes.string,
    fontWeight: PropTypes.oneOf([
        'font-light',
        'font-normal',
        'font-medium',
        'font-semibold',
        'font-bold',
        'font-extrabold',
        'font-black',
        '',
    ]),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    children: PropTypes.node,
};

export default SectionHeading;
