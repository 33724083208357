import Link from 'next/link';
import PropTypes from 'prop-types';
import Icon from '@/atoms/Icon';
import Logo from '@/atoms/Logo';
import Button from '@/atoms/Button';
import MenuList from '@/atoms/MenuList';
import SlideShow from '@/organisms/MobileMenu';
import { useState, useEffect } from 'react';
import { useRequestCallbackStore } from 'store/requestCallback';
import RequestCallback from '@/organisms/RequestCallback';

const Navbar = ({
    className,
    logo,
    logoAlt,
    logoWidth,
    logoHeight,
    menuItem,
    handleCart,
    handleProfile,
    handleCallBack,
    handleTrackOrder,
    count,
}) => {
    const [show, setShow] = useState(false);
    const {
        isOpenRequestCallback,
        setIsOpenRequestcallback,
        isRequestMessagaeRecieve,
    } = useRequestCallbackStore();

    function onSubmit() {}

    useEffect(() => {}, [isOpenRequestCallback]);

    return (
        <div
            className={`fixed top-0 navbar py-4 lg:py-[26px] w-full bg-white z-[100] ${className}`}
        >
            <div className="container xl:max-w-[1360px]">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <Link href="/" className="flex-shrink-0">
                            <Logo
                                src={logo}
                                alt={logoAlt}
                                width={logoWidth}
                                height={logoHeight}
                            />
                        </Link>
                        <MenuList
                            className="ml-10 hidden xl:block"
                            menuItem={menuItem}
                        />
                    </div>

                    <div className="flex items-center justify-end gap-4">
                        <Button
                            label="Request Call Back"
                            style="ghost"
                            size="small"
                            onClick={handleCallBack}
                            btnClass="hidden md:block"
                        />

                        <Button
                            label="Track Order"
                            style="ghost"
                            size="small"
                            onClick={handleTrackOrder}
                            btnClass="hidden md:block"
                        />

                        <div className="relative" onClick={handleCart}>
                            <Icon
                                src="/images/icons/cart.svg"
                                width={24}
                                height={24}
                                alt="cart"
                                className="ml-3.5 cursor-pointer"
                            />
                            {count >= 1 && (
                                <span className="absolute -right-1 -top-2 w-4 h-4 flex items-center justify-center bg-primary-default text-white rounded-full text-[10px]">
                                    {count}
                                </span>
                            )}
                        </div>

                        <Icon
                            src="/images/icons/profile.svg"
                            width={24}
                            height={24}
                            alt="profile"
                            onClick={handleProfile}
                        />

                        <Icon
                            src="/images/icons/hamburger.svg"
                            width={24}
                            height={24}
                            alt=""
                            onClick={() => {
                                setShow(true);
                            }}
                            className="xl:hidden cursor-pointer"
                        />
                    </div>
                </div>
            </div>
            <div className="xl:hidden">
                <SlideShow
                    show={show}
                    handleClose={() => setShow(false)}
                    list={menuItem}
                    handleCallBack={handleCallBack}
                    handleTrackOrder={handleTrackOrder}
                />
            </div>
            {isOpenRequestCallback && (
                <RequestCallback
                    isRequestRaised={isRequestMessagaeRecieve}
                    isRequestCallback={false}
                    onClose={() => setIsOpenRequestcallback(false)}
                    isOpenRequestCallback={true}
                    handleSubmit={onSubmit}
                />
            )}
        </div>
    );
};

Navbar.defaultProps = {
    className: 'shadow-xs',
    logo: '/images/logo.svg',
    logoAlt: 'Logo',
    logoWidth: 117,
    logoHeight: 40,
    menuItem: [],
    handleCart: () => {},
    handleProfile: () => {},
    handleCallBack: () => {},
    handleTrackOrder: () => {},
    count: 5,
};

Navbar.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string,
    logoAlt: PropTypes.string,
    logoWidth: PropTypes.number,
    logoHeight: PropTypes.number,
    menuItem: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
    handleCart: PropTypes.func,
    handleProfile: PropTypes.func,
    handleCallBack: PropTypes.func,
    handleTrackOrder: PropTypes.func,
    count: PropTypes.number,
};

export default Navbar;
